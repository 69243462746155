import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinkItems,
    FooterLinksWrapper,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcons,
    WebsiteRights,
    SocialIconLink
} from './FooterElements';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> Kontakt </FooterLinkTitle>
                            <FooterLink href="tel:+4915140338777" style={{color: 'black'}}>Call +4915140338777</FooterLink>
                            <FooterLink href="mailto:info@ys-engineering.de" style={{color: 'black'}}>Email info@ys-engineering.de</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle as={Link} to='/impresum' style={{
                                color: 'black',
                                textDecoration: 'none',
                                border: '2px solid black',
                                borderRadius: '10px',
                                textAlign: 'center', 
                                padding: '10px'
                            }}>
                                Impressum
                            </FooterLinkTitle>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome} style={{color: 'black'}}> 
                            YS Engineering UG
                        </SocialLogo>
                        <WebsiteRights style={{color: 'black'}}> YS Engineering UG © {new Date().getFullYear()} All rights reserved. </WebsiteRights>
                        <SocialIcons>
                            {/* Раскомментируйте следующие строки, если хотите включить иконки социальных сетей */}
                            {/* <SocialIconLink href="//www.facebook.com" target="_blank" aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="//www.instagram.com" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="//www.youtube.com" target="_blank" aria-label="Youtube">
                                <FaYoutube />
                            </SocialIconLink>
                            <SocialIconLink href="//www.twitter.com" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="//www.linkedin.com" target="_blank" aria-label="Linkedin">
                                <FaLinkedin />
                            </SocialIconLink> */}
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    );
}

export default Footer;
