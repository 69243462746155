import React, { useState, useEffect } from 'react';
// Импорт изображений
import Hero1 from '../../videos/hero-1.jpg';
import Hero2 from '../../videos/hero-2.jpg';
import Hero3 from '../../videos/hero-3.jpg';

import {
    HeroContainer,
    HeroBg,
    HeroImageBg,
    HeroContent,
    HeroH1,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight
} from './HeroElements';

import { Button } from '../ButtonElement';

// Массив изображений для слайдера
const heroImages = [Hero1, Hero2, Hero3];

const HeroSection = () => {
    const [hover, setHover] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isImageVisible, setIsImageVisible] = useState(true);

    // Функция для переключения изображений каждые 2 секунды
    useEffect(() => {
        const imageInterval = setInterval(() => {
            setIsImageVisible(false); // Скрываем изображение перед переключением
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
                setIsImageVisible(true); // Показываем новое изображение после переключения
            }, 1000); // Ждем 1 секунду для плавного перехода
        }, 3000); // Интервал между переключениями (3 секунды)

        return () => clearInterval(imageInterval); // Очистка интервала при размонтировании компонента
    }, []);

    const onHover = () => {
        setHover(!hover);
    };

    return (
        <HeroContainer id="home">
            <HeroBg>
                {/* Отображаем текущее изображение с плавной анимацией */}
                <HeroImageBg src={heroImages[currentImageIndex]} alt="Hero Image" isVisible={isImageVisible} />
            </HeroBg>
            <HeroContent>
                <HeroH1>Technologie von morgen, Qualität von heute</HeroH1>
                <HeroBtnWrapper>
                    <Button
                        to="susanne"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary="true"
                        dark="true"
                        smooth={true}
                        duration={700}
                        spy={true}
                        activeClass="active"
                        exact="true"
                    >
                        Management-Team
                        {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;
