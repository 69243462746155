import React from 'react'
import Icon1 from '../../images/FTTx.png'
import Icon2 from '../../images/LWL.png'
import Icon3 from '../../images/NE4.png'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements'

const Services = () => {
  return (
    <ServicesContainer id='services'>
        <ServicesH1> Unsere Produkte </ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
              <ServicesIcon src={Icon1}/>
              <ServicesH2>FTTx</ServicesH2>
              <ServicesP> Hochgeschwindigkeitsanschluss mit Glasfasertechnologien für Gebäude </ServicesP>
              <a href="https://drive.google.com/file/d/1WSbtUzgYXXLA9T_MQoZ2uktlu0UAzJgL/view?usp=drive_link" target="_blank" rel="noopener noreferrer">PDF</a>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon2}/>
              <ServicesH2> LWL Konfektionen </ServicesH2>
              <ServicesP> Lichtwellenleiter mit Steckern für eine schnelle und zuverlässige Installation </ServicesP>
              <a href="https://drive.google.com/file/d/19BlCxD3RLc2E8EcOp4RgYE9vVwcmCEVJ/view?usp=drive_link" target="_blank" rel="noopener noreferrer">PDF</a>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon3}/>
              <ServicesH2> NE4 ANSCHLUSS </ServicesH2>
              <ServicesP> Vernetzung im Gebäude zur Verbindung von Endgeräten </ServicesP>
              <a href="https://drive.google.com/file/d/1dAeZVDcYdUFSqzzHdAGEXhr97YIIb1I8/view?usp=drive_link" target="_blank" rel="noopener noreferrer">PDF</a>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services