import React from 'react';
import {
    Container,
    FormWrap,
    Icon,
    FormContent,
    Form,
    FormH1,
    Text
} from './SigninElements';
import style from './style.module.css';

const SignIn = () => {
    return (
        <Container>
            <FormWrap>
                <Icon to="/">YS Engineering UG</Icon>
                <FormContent className={style.Form}>
                    <Form className={style.Form} action="#">
                        <FormH1>Impressum:</FormH1>
                        <FormH1>YS-Engineering UG (haftungsbeschränkt)</FormH1>
                        <FormH1>Geratsberger Str. 9a, 84130 Dingolfing</FormH1>
                        <FormH1>Tel. +49 (151) 40338777</FormH1>
                        <FormH1>Email: <a className={style.whiteLink} href="mailto:info@ys-engineering.de">info@ys-engineering.de</a></FormH1>
                        <FormH1>Website: <a className={style.whiteLink} href="https://www.ys-engineering.de">www.ys-engineering.de</a></FormH1>
                        <FormH1>HRB 14431, Amtsgericht Landshut</FormH1>
                        <FormH1>USt-Id-Nr. DE369780754</FormH1>
                        <FormH1>Geschäftsführer: Susanne Geier, Yurii Kravchenko</FormH1>
                        <FormH1>Europäische Online-Streitbeilegungs-Plattform:</FormH1>
                        <Text className={style.textStyle}>
                            Seit dem 15. Februar 2016 stellt die EU-Kommission eine Plattform für außergerichtliche Streitschlichtung bereit. Als
                            Verbraucher gibt dies die Möglichkeit, Streitigkeiten im Zusammenhang mit der Online-Bestellung zunächst ohne die
                            Einschaltung eines Gerichts zu klären. Die Streitbeilegungs-Plattform ist unter dem externen Link
                            <a className={style.whiteLink} href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.adr.show">webgate.ec.europa.eu/odr</a> erreichbar.
                        </Text>
                        <Text>
                            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                            verantwortlich. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                            hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                            entfernen.
                        </Text>
                        <Text>
                            Urheberrecht: Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
                            bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für
                            den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten
                            Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                            Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                        </Text>
                        <div></div>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    );
}

export default SignIn;