// undraw.co to get svg images

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Unser Service',
    headline: '',
    description: 'YS Engineering UG ist ein führendes Unternehmen in der Glasfaserinstallation. Wir bieten maßgeschneiderte Lösungen, die ultraschnelles Internet an jeden Ort bringen. Unsere zertifizierten Fachkräfte arbeiten mit modernster Technik, um höchste Qualität und Zuverlässigkeit zu gewährleisten.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: 'https://i.pinimg.com/originals/6b/b6/5b/6bb65b184f4b26a7897cd989d42d3811.gif',
    alt: 'GugaAbout',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Über uns',
    headline: 'Willkommen auf der Website von YS Engineering UG!',
    description: 'Unsere Glasfasertechnologie ist nicht nur schnell, sondern auch umweltfreundlich. Durch den geringeren Energieverbrauch und die lange Lebensdauer unserer Produkte tragen wir dazu bei, die CO₂-Emissionen zu reduzieren und nachhaltige Netzwerklösungen für die Zukunft zu schaffen. Setzen Sie auf eine Technologie, die Leistung und Umweltbewusstsein vereint',
    imgStart: true,
    img: 'https://i.pinimg.com/originals/07/f5/55/07f55516c49808f89f7fd3935b0af04f.gif',
    alt: 'GugaDiscover',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'yurii',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Yurii Kravchenko  (CEO)',
    description: "Geschäftsführer (CEO) von YS Engineering UG, ist ein herausragender Führer im Bereich moderner Technologien und Netzwerklösungen. Unter seiner Führung kombiniert das Unternehmen erfolgreich innovative Fortschritte mit traditioneller deutscher Qualität. Yurii ist bekannt für seine strategische Vision und seinen Streben nach Perfektion, was YS Engineering zu einer führenden Position in der Glasfaserbranche verholfen hat",
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../images/Avatar.svg').default,
    alt: 'GugaSignUp',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjFour = {
    id: 'susanne',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Susanne Geier (CEO)',
    description: "Mitgeschäftsführerin (CEO) von YS Engineering UG, ist eine dynamische Führungspersönlichkeit, die moderne Technologien und nachhaltige Innovationen geschickt vereint. Unter ihrer Leitung stärkt das Unternehmen seine Position als Branchenführer, indem es höchste deutsche Qualität mit zukunftsweisenden Lösungen kombiniert. Susanne ist bekannt für ihre Fähigkeit, komplexe Projekte erfolgreich zu leiten, und für ihr tiefes Engagement für die langfristige Entwicklung von YS Engineering. Ihr visionäres Denken trägt wesentlich zum internationalen Erfolg des Unternehmens bei.",
    buttonLabel: 'Mehr Erfahren',
    imgStart: true, // Изображение с правой стороны
    img: require('../../images/Avatar2.svg').default, // Другое изображение для Сюзанны
    alt: 'Susanne Geier',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjFive = {
    id: 'susanne',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Unser Team',
    headline: 'Susanne Geier (CEO)',
    description: "Mitgeschäftsführerin (CEO) von YS Engineering UG, ist eine dynamische Führungspersönlichkeit, die moderne Technologien und nachhaltige Innovationen geschickt vereint. Unter ihrer Leitung stärkt das Unternehmen seine Position als Branchenführer, indem es höchste deutsche Qualität mit zukunftsweisenden Lösungen kombiniert. Susanne ist bekannt für ihre Fähigkeit, komplexe Projekte erfolgreich zu leiten, und für ihr tiefes Engagement für die langfristige Entwicklung von YS Engineering. Ihr visionäres Denken trägt wesentlich zum internationalen Erfolg des Unternehmens bei.",
    buttonLabel: 'Mehr Erfahren', // Краткий текст для кнопки
    imgStart: true, // Изображение с правой стороны
    img: require('../../images/Avatar2.svg').default, // Используйте путь к изображению
    alt: 'Susanne Geier',
    dark: false,
    primary: false,
    darkText: true
};







